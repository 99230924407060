<template>
    <div>
      <el-breadcrumb  separator-class="el-icon-arrow-right">
         <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item style="color: #fff;">运营管理</el-breadcrumb-item>
        <el-breadcrumb-item>返利设置</el-breadcrumb-item>
      </el-breadcrumb>
      <el-card style=" overflow: auto; ">
      <!--商品数据展示区域-->
        <el-table
          :data="tableData"
          style="width: 100%;"
        
          ref="singleTable"
          highlight-current-row
          row-key="id"
          stripe >
          <el-table-column prop="id" label="id" width="150" show-overflow-tooltip/>
          
          <el-table-column prop="type" label="业务类型" width="300"  show-overflow-tooltip/>
          <el-table-column prop="value" label="业务值" width="100" show-overflow-tooltip/>
          <el-table-column prop="remarks" label="备注" width="600" show-overflow-tooltip/>
          <el-table-column prop="typeName" label="状态" width="200" show-overflow-tooltip>
            <template  slot-scope="scope">
            <p v-if="scope.row.state==1">正常</p>
            <p v-else-if="scope.row.state==0">失效</p>
            </template>
      </el-table-column>
          <!-- <el-table-column label="操作"  min-width="200" fixed="right"  >
            <template  slot-scope="scope">
            <el-button @click="ondialogVisible(scope.row)" size="mini"  type="text">编辑</el-button>
        </template>
          </el-table-column> -->
        </el-table>
        <!--分页-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <el-dialog
      width="30%"
      title="编辑返利"
      :append-to-body="true"
      :visible.sync="dialogVisible"
    >
      <el-form :model="form">
        <el-form-item label="备注" required>
          <el-col class="mr15" :span="18">
            <el-input size="mini"  placeholder="请输入备注"    />
          </el-col>
        </el-form-item>
    
        <el-form-item label="状态" required>
          <el-col class="mr15" :span="18">
            <el-input size="mini" placeholder="请输入状态"   />
          </el-col>
        </el-form-item>
        <el-form-item label="类型" required>
          <el-col class="mr15" :span="18">
            <el-input size="mini"  placeholder="请输入类型"   ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="配置" required>
            <el-col class="mr15" :span="18">
              <el-input size="mini"  placeholder="请输入配置"   ></el-input>
            </el-col>
          </el-form-item>
        <el-form-item label="显示状态" required>
          <el-radio v-model="radio" label="1">启用</el-radio>
          <el-radio v-model="radio" label="2">停用</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="innerVisible = false">取消</el-button>
        <el-button type="primary" @click="innerVisible = false">确定</el-button>
      </span>
    </el-dialog>
      </el-card>
    </div>
  </template>
  
  <script>
  // 引入拖动列表组件
  import time from '../../utils/time'
  import dataFunction from "@/utils/dataFunction";
  import {searchRebateList } from "@/api";
  
  export default {
    name: "UsesignList",
    data() {
      return {
        dialogVisible:false,
        // 弹窗页面设置
        setType: 0,
        loading:  false,
        // 新建区域弹窗
        innerVisible: false,
        form: {},
        fileList: [],
        outerVisible: false,
        radio: "1",
        // 查找商品列表参数对象
        queryInfo: {
         page: 1,
          size: 10,
    
    
        },
        time: "",
  
        total: 0,
         tableData:[],
      };
    },
      created() {
        this.getGoodsList()
      },
    methods: {
        ondialogVisible(e){
console.log(e);
            this.dialogVisible=true
        },
    // 模糊搜索
    onkeyword(){
  this.queryInfo.page=1
   this.getGoodsList()
    },
  
      /*
       * 页面加载请求所有的意见数据
       * 默认请求第一页 十条数据
       * */
      
      async getGoodsList() {
 
          const { data: res } = await searchRebateList(this.queryInfo);
          if (res.code !== 200) return this.$message.error("意见反馈列表获取错误");
          this.tableData = res.body.list;
          this.total = res.body.total
           for (let index = 0; index < this.tableData.length; index++) {
   this.tableData[index].updateTime= time(this.tableData[index].updateTime)
  }
      },
  
      handleCurrentChange(data) {
        this.queryInfo.page = data;
        this.getGoodsList();
      },
      // 省份城市搜索
      handleSizeChange(newSize) {
        this.queryInfo.size = newSize;
        this.getGoodsList();
      },
      // 日期搜索
      dateHandle() {
        this.queryInfo.pageNum = 1;
        this.queryInfo.time = dataFunction(this.time);
        this.getGoodsList();
      },
  
      // 选择市
      oncity(data) {
        this.queryInfo.page = 1;
        this.queryInfo.cityCode = data.code.substring(0,4)
     
        this.getGoodsList();
      },
  
      // 退出
      logout() {
        window.sessionStorage.clear();
        this.$router.push("/login");
      },
      // 返回首页
      backHome() {
        this.$router.push("/index");
        window.sessionStorage.removeItem("activePath");
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  
  .mr15 {
    margin-right: 15px;
  }
  
  p {
    margin: 0;
    padding: 0;
  }
  
  ol,
  ul {
    margin: 0;
    padding: 0;
  }
  
  .content ul {
    list-style: none;
  
    li {
      display: flex;
      align-items: center;
      margin-top: 25px;
  
      span {
        font-weight: bold;
        display: block;
        width: 120px;
      }
  
      img {
        display: block;
  
        width: 100px;
      }
    }
  }
  .CheckInTimeTitle {
    font-size: 12px;
    color: #606266;
    margin-right: 5px;
  }
  .Price {
    display: flex;
    flex-wrap: wrap;
  }
  
  .resetInputSize {
    width: 100px;
  }
  
  .quill-editor {
    line-height: normal;
  }
  
  .goodDesc p img {
    width: 100px;
  }
  .el-buttonr {
    width: 70px;
    height: 25px;
  }
  .goods-details {
    .el-row {
      margin-bottom: 10px;
    }
  
    .goodDesc {
      p {
        img {
          width: 100px !important;
        }
      }
    }
  }
  
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .el-icon-s-fold,
  .el-icon-s-unfold {
    font-size: 35px;
    margin: 22px;
    color: #999;
  }
  .mr15 {
    margin-right: 15px;
  }
  .v-distpicker ::v-deep select {
    height: 29px !important;
  
    padding-top: 0.4rem;
    font-size: 12px;
  }
  </style>